import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Le métier de dev à l'ère du numérique responsable.
    </Text>
    <p>{`🌱 En tant que dev, ne pouvons-nous pas, nous aussi, participer à un monde moins pollué et plus inclusif ?
Avec le code que nous écrivons chaque jour, nous pourrions apporter notre part à la reconstruction d'un nouvel avenir, et prendre plaisir à le faire.`}</p>
    <p>{`💻 Avec DevCooc, je vous aide, développeuses et développeurs passionné·e·s, à pratiquer un numérique plus responsable au quotidien dans votre code et votre métier.`}</p>
    <p>{`🤝 Si vous êtes intéressé·e·s par ma démarche, contactez-moi sur `}<a parentName="p" {...{
        "href": "https://fr.linkedin.com/in/geoffreyarthaud"
      }}>{`LinkedIn`}</a>{` !`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      